@import '../../shared/settings';

.search-filters {
    line-height: 20px;
    font-size: 14px;

    .filters {
        padding: 0;
    }

    &.search-filters-in-tray {
        padding-bottom: 100px; // make room for button

        .sidebar-filter > * {
            padding-left: 16px;
            padding-right: 16px;
        }

        > .btn {
            display: block;
            position: fixed;
            right: 20px;
            bottom: 20px;
            left: 20px;
            margin: 0;
            width: calc(90vw - 40px);
            line-height: 21px;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .pagination-ordering {
        display: flex;
        align-items: center;
        label {
            font-size: 16px;
            font-weight: bold;
        }
        select {
            margin-left: auto;
        }
    }
}

.sidebar-filter {
    padding: 14px 0;
    border-bottom: 1px solid rgba($light-grey, 0.5);
    font-size: 14px;

    @include respond-to(not-mobile) {
        &:first-child {
            padding-top: 0;
        }
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    small {
        font-size: inherit;
    }

    a {
        display: block;
        line-height: 28px;
        color: $dark-grey;

        &:hover {
            text-decoration: underline;
        }

        small {
            color: inherit;
        }

        &.clear {
            svg {
                position: relative;
                top: 3px;
                margin-right: 8px;
                width: 16px;
                height: 16px;

                circle {
                    fill: $light-grey;
                }
            }

            &:hover {
                svg {
                    circle {
                        fill: var(--warning);
                    }
                }
            }
        }

        &.filter-back {
            color: var(--brand);

            svg {
                transform: rotate(0.5turn);
                margin-right: 8px;
                fill: var(--brand);
            }
        }

        .checkbox {
            top: 3px;
            margin-right: 8px;
        }
    }

    .searchbox {
        margin-bottom: 10px;
        width: calc(100% - 10px);
    }

    .color-filter,
    .multiselect-filter,
    .chosen-filter {
        @include styledScrollbar(5px, rgba($light-grey, 0.5));
        overflow-y: scroll;
        max-height: 200px;
        margin: 0 11px 0 0;
    }

    .color-filter {
        a {
            display: flex;
            align-items: center;

            .color,
            .multicolor {
                width: 16px;
                height: 16px;
            }
        }
    }
}

.filter-header {
    display: flex;
    align-items: center;
    position: relative;

    h4,
    button:first-child,
    label {
        color: var(--dark-grey);
        font-size: 16px;
        font-weight: bold;
        margin: 0 auto 0 0;
        line-height: 36px;
    }

    a {
        color: var(--brand);
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    button:first-child {
        position: relative;
        border-radius: 0;
        background: none;
        padding: 0;
        width: 100%;
        height: auto;
        text-align: left;
        display: flex;
        align-items: center;

        svg {
            margin-left: auto;
            transform: rotate(180deg);
            width: 7px;
            height: 5px;
            fill: var(--dark-grey);
            margin-right: 10px;
        }
    }
}

.filter-collapsed .filter-header {
    button:first-child {
        svg {
            transform: rotate(0deg);
        }
    }
}
