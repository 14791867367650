@import '../../shared/settings';

.btn-switch {
    display: inline-block;
    position: relative;
    background: none;

    padding: 0;
    width: 50px;
    height: 28px;

    input[type] {
        opacity: 0;
        margin: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        display: block;
        transition: all 0.15s ease;
        margin: 0;
        border-radius: 17px;
        background: $light-grey;
        width: 100%;
        height: 100%;

        div {
            display: block;
            position: absolute;
            top: 3px;
            left: 3px;
            transition: left $fast;
            border-radius: 11px;
            background: white;
            width: 22px;
            height: 22px;

            &::after {
                position: absolute;
                top: 7px;
                left: 7px;
                transition: opacity $fast;
                border-radius: 4px;
                background: $light;
                width: 8px;
                height: 8px;
                content: ' ';
            }
        }
    }

    &:hover {
        label {
            div {
                &::after {
                    background: var(--success);
                }
            }
        }

        input {
            &:checked ~ label {
                div {
                    &::after {
                        background: $light-grey;
                    }
                }
            }
        }
    }

    input {
        &:checked ~ label {
            background: var(--success);

            div {
                left: 25px;
            }
        }

        &:disabled ~ label {
            opacity: 0.5;
        }
    }
}
