@import '../../shared/settings';

.small-box {
    border-radius: 10px;
    background: white;
    max-width: 380px;
    width: 100%;
    overflow: hidden;

    @include respond-to-max(380px) {
        border-radius: 0;
        margin: 0;
    }

    .form-error {
        margin: 0;
        border-radius: 0;
    }

    .form-row > a {
        margin-top: 10px;
        display: inline-block;
        font-size: 14px;
    }
}


.small-box-content,
.small-box-header { 
    padding: 32px;

    h2 {
        margin: 0 0 10px;
    }

    p {
        margin: 10px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.grid-block p a, 
    &.grid-block p .link,
    &.grid-block p .btn-link {
        color: var(--brand);
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
        }
    }

    @include respond-to(mobile) {
        padding: 16px;
    }
}

.small-box-header {
    text-align: center;

    p {
        font-size: 16px;
    }
}

.small-box-content {
    .grid-block {
        padding: 0;
        background: none;
    }

    .grid-block-holder + .grid-block-holder {
        margin-top: 32px;
    }

    .grid-buttons {
        .btn {
            width: 100%;
            font-weight: bold;
            font-size: 16px;
            height: 46px;
            border-radius: 23px;
        }
    }
}

.small-box-header + .small-box-content {
    padding-top: 0;
}